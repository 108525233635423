@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700;800;900&display=swap');

:root{
 --lightblue: #44a3f8;
}

*{
  overflow-x: hidden;
}

body, html{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}

.container-fluid{
  margin: 0;
  padding: 0;
}

.card-nft > img {
  width: 100%;
}

.side-menu{
  background: transparent linear-gradient(153deg, #A6F6F1 0%, #2FA4FF 100%) 0% 0% no-repeat padding-box;
  width: 552px;
  height: 100vh !important;
  clip-path: polygon(0 0, 60% 0%, 100% 100%, 0% 100%);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  left: 0 !important;
  z-index: 9999;
  top: 0 !important;
  animation: reveal 1s;
}

@keyframes reveal {
  0% {
    width: 0;
  }
  100% {
    width: 552px;
  }
}

.input-components{
  overflow: hidden;
}

.input-button-left{
  border: 0;
  border-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: #000;
  color: white;
  padding: 10px 20px;
  outline: none !important;
}
.input-button-right{
  border: 0;
  border-radius: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  background-color: #000;
  color: white;
  padding: 10px 20px;
  outline: none !important;
}
.btn-mint{
  border-radius: 15px;
  border: 0;
  padding: 10px 0px;
  font-weight: bold;
  color: #fff;
  background: transparent linear-gradient(99deg, #A6F6F1 0%, #2FA4FF 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #46B4FC77;
  border-radius: 36px;
}
.main-title{
  color: var(--lightblue);
  font-weight: bold;
}
.logoProfilo{
width: 55%;

}

.card-title{
  margin-top: 16px !important;
  margin-bottom: 12px;
}

.sectionProfile{
  margin: 20px 0;
  min-height: 40vh !important;
  padding: 20vh 20px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.minted{
  font-weight: 500;
}
.menu-item{
  font-weight: bold;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
  font-size: 16px;
  margin-left: 60px;
  cursor: pointer;
}
.wallet{
  margin-left: 70px;
}
.list-links{
  margin-top: 40px;
  padding: 0;
  margin-left: 20px;
}
.mindmap-card > h3{
  margin-top: 15px;
  font-size: 1.55rem !important;
}

li{
  margin: 0;
}
.btn-mint{
  width: 250px;
  margin-left: auto;
  margin-right: auto;
}
.main{
  height: 100vh;
  overflow-y: scroll;
}
.input-value{
  padding: 13px 20px;
  background-color: #000;
  color: #fff;
}
.hamburger-menu{
  z-index: 99999;
  position: fixed;
  color: #000;
  left: 20px;
  top: 10px;
  cursor: pointer;
  font-size: 2.5rem;
}
.switch-collection{
  border-radius: 30px;
  background-color: #000;
  padding: 5px 5px;
  height: 40px;
  width: 230px;
  font-size: 16px;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 991px) {
  .switch-collection{
    left: 50%;
    transform: translateX(-50%);
  }
  #second-title{
    text-align: center;
  }
  .list-links{
    margin-left: 0px;
  }
}

.switch-tab-left{
  border-radius: 30px;
  font-weight: bold;
  cursor: pointer;
  color: #fff;
  transition: .4s;
  background-color: #000;
  border: 0;
  outline: none !important;
  font-size: 14px;
  outline: none !important;
  padding: 5px 12px;
}
.switch-tab-right{
  border-radius: 30px;
  font-weight: bold;
  border: 0;
  cursor: pointer;
  transition: .4s;
  color: #fff;
  background-color: #000;
  outline: none !important;
  font-size: 14px;
  outline: none !important;
  padding: 5px 12px;
}
.card-nft{
  margin-left: auto;
  margin-right: auto;
  background: transparent linear-gradient(99deg, #A6F6F1 0%, #2FA4FF 100%) 0% 0% no-repeat padding-box;
  border-radius: 30px;
}

.nft-name{
  font-weight: bold;
  margin-bottom: 0;
}
.nft-serial{
  color: #ccc;
}
.ml-25{
  margin-left: 25px;
}
.title-spacing{
  margin-top: 100px !important;
}
.sidebar-logo{
  margin-left: 60px;
  margin-top: 60px;
}
.logo-main{
  width: 230px;
}
.menu-item.active{
  background-color: #000;
  color: #fff;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 220px;
  border-radius: 10px;
  margin-left: 50px;
}
.switch-active{
  color: var(--lightblue);
  background-color: #fff;
}

.banner-content{
  display: flex;
  min-height: 90vh;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 7vw;
}
.banner-title{
  font-weight: bold;
  font-size: 3rem;
  overflow-y: hidden;
}
.banner-parag{
  color: #888;
}
.color-white{
  color: white !important;
}
.btn-inverted{
  color: var(--lightblue) !important;
  background: #fff !important;
  border: solid 2px var(--lightblue) !important;
}
.banner-container{
  min-height: 90vh;
  background-color: #cccccc2e;
  position: relative;
}

img{width: 175px;}

.banner-logo{
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  position: absolute;
  top: 5%;
  left: 2vw;
  width: 100%;
  height: 95%;
}
.banner-mano{
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 48vw;
  position: absolute;
  top: 5%;
  left: 2vw;
  width: 100%;
  height: 95%;
}

.content-spacing{
  padding-top: 120px;
  padding-left: 80px;
  padding-right: 80px;
}
.switch-spacing{
  margin-top: 50px;
}
.parag-size{
  width: 620px;
}
.color-lightblue{
  color: var(--lightblue) !important;
}
.skew_bg.bg1 {
  transform: skew(-10deg, 0deg);
}
.content {
  margin-left: 10px;
  position: absolute;
  z-index: 999;
}
.skew_bg {
  min-height: 600px;
  width: 50%;
  background: #fff;
  margin-left: -55px;
}
.bg-gradient{
  background: transparent linear-gradient(99deg, #07f5f3 0%, #2FA4FF 100%) 0% 0% no-repeat padding-box !important;
}
.section-spacing{
  position: relative;
  margin-top: 120px;
  min-height: 600px;
}
.mindmap-cards{
  position: absolute;
  z-index: 99;
  top: 50%;
  transform: translateY(-50%);
  margin-top: 20px;
  margin-right: 80px;
}
.mindmap-card{
  border-radius: 36px;
  background-color: #fff;
  width: 90vh;
  max-width: 400px;
  margin: 10px auto;
  padding: 30px;
  box-shadow: 1px 1px 2px 2px var(--lightblue);
}
.mindmap-card hr{
  margin-top: 20px;
  width: 70%;
  border: solid 1px var(--lightblue);
}
.color-gray{
  color: #aaa !important;
}
.card-link{
  font-weight: bold;
}
.footer-section{
  min-height: 100px;
}
.center-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
}
.full-high-row{
  height: 100% !important;
  position: relative;
}
.nav-link i{
  color: #000 !important;
  vertical-align: middle;
  font-size: 1.3rem;
}
.nav-link img{
  width: 1.6rem;
}
.navbar-brand{
  margin-left: 80px;
}
.navbar-nav{
  margin-right: 120px;
}
.divider{
  color: #000 !important;
}
.navbar{
  background-color: #fff !important;
  box-shadow: -16px 0px 40px -21px #00b8fc;
}
.color-black{
  color: #000 !important;
}
.social-icons i{
  vertical-align: middle;
  margin: 0 10px;
  font-size: 1.3rem;
}
.social-icons img{
  width: 1.6rem;
}
.center-content{
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center !important;
}
.link-container-center{
  margin: 0 25px;
  padding: 0 25px;
  border-left: solid 1px var(--lightblue);
  border-right: solid 1px var(--lightblue);
}
.navbar-toggler{
  border: none !important;
  outline: none !important;
  cursor: pointer;
}

@media screen and (max-width: 1350px) {
  .mindmap-card{
    width: 350px;
  }
}
@media screen and (max-width: 1170px) {
  .mindmap-card{
    width: 330px;
  }
}

@media screen and (max-width: 991px) {
  .center-content{
    min-height: 120px;
    height: auto;
  }
  .navbar-nav .nav-link{
    margin-right: 0;
    margin-left: 80px;
  }
  .skew_bg{
    min-height: 950px;
    height: auto;
    margin-left: -90px;
  }
  .section-spacing{
    min-height: 950px;
    margin-left: 15px;
  }
  .mindmap-card{
    width: 100%;
    margin-left: auto;
    margin-right: auto;

  }
  .mindmap-cards{
    margin-top: 50px;
  }
  .parag-size{
    position:  relative !important;
    width: 100%;
    padding-right: 100px;
  }

  .banner-logo{
    display: none;
  }
  .banner-mano{
  }
}

@media screen and (max-width: 601px) {
  .banner-logo{
    display: none;
  }
  .banner-mano{
    display: none;

  }

  .sectionProfile{
    padding: 10vh 20px;
  }

  .p-0{
    font-size: 8px;
  }

  .footer-section b{
    font-size: 10px;
  }


  .accordion.accordion-flush {
    padding: 0px 15px;
}
.navbar-brand {
  margin-left: 10px;
}
.banner-content{
  margin-left: auto;
}
}